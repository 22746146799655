var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg_gray" }, [
    _vm.isLoading
      ? _c(
          "div",
          [
            _c(
              "center",
              { attrs: { id: "loadingbox" } },
              [_c("van-loading", { attrs: { size: "68px" } })],
              1
            )
          ],
          1
        )
      : _vm._e(),
    !_vm.isLoading
      ? _c(
          "div",
          [
            _c("headbox", {
              attrs: {
                title: "订单报表",
                isbackPath: true,
                path: "person",
                bgColor: "#f83637",
                iswhite: "true"
              }
            }),
            _c("div", { staticClass: "whiteBg scroll-x" }, [
              _vm._m(0),
              _c("div", { staticClass: "flex top" }, [
                _c("div", { staticClass: "category" }, [_vm._v("黄金(克)")]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.auData.delayBuy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.auData.delaySell))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.auData.buy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.auData.sell))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.mortgageData.weight))
                ])
              ]),
              _c("div", { staticClass: "flex top" }, [
                _c("div", { staticClass: "category" }, [_vm._v("白银(克)")]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.agData.delayBuy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.agData.delaySell))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.agData.buy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.agData.sell))
                ]),
                _c("div", { staticClass: "number" }, [_vm._v("--")])
              ]),
              _c("div", { staticClass: "flex top" }, [
                _c("div", { staticClass: "category" }, [_vm._v("铂金(克)")]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.ptData.delayBuy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.ptData.delaySell))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.ptData.buy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.ptData.sell))
                ]),
                _c("div", { staticClass: "number" }, [_vm._v("--")])
              ]),
              _c("div", { staticClass: "flex top" }, [
                _c("div", { staticClass: "category" }, [_vm._v("钯金(克)")]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.pdData.delayBuy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.pdData.delaySell))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.pdData.buy))
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm.pdData.sell))
                ]),
                _c("div", { staticClass: "number" }, [_vm._v("--")])
              ])
            ]),
            _c("div", { staticClass: "whiteBg" }, [
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("总押金(元)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.deposit))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("可用押金(元)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.freeEarnestDeposit))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("占用押金(元)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.earnestDeposit))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("未结订单保管费(元)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.unfinishStorage))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("卖料未结总押金比例")]),
                _c("div", [
                  _vm._v(_vm._s(_vm.orderData.sellDepositPercent) + "%")
                ])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("买料未结总押金比例")]),
                _c("div", [
                  _vm._v(_vm._s(_vm.orderData.buyDepositPercent) + "%")
                ])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("卖料未结订单均价(元/克)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.auViolatePrice))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("买料未结订单均价(元/克)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.auViolateBuyPrice))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("待交料订单均价(元/克)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.auBuyPrice))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("待提料订单均价(元/克)")]),
                _c("div", [_vm._v(_vm._s(_vm.orderData.auSellPrice))])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("卖料需补押金(元)")]),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.orderData.sellDeposit < 0
                        ? 0
                        : _vm.orderData.sellDeposit
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "flex-between bottom" }, [
                _c("div", [_vm._v("买料需补押金(元)")]),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.orderData.buyDeposit < 0
                        ? 0
                        : _vm.orderData.buyDeposit
                    )
                  )
                ])
              ])
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex top" }, [
      _c("div", { staticClass: "category" }),
      _c("div", { staticClass: "title" }, [_vm._v("买料未结")]),
      _c("div", { staticClass: "title" }, [_vm._v("卖料未结")]),
      _c("div", { staticClass: "title" }, [_vm._v("待提料")]),
      _c("div", { staticClass: "title" }, [_vm._v("待交料")]),
      _c("div", { staticClass: "title" }, [_vm._v("存料中")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }